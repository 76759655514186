function isServer() {
  return typeof window === "undefined"
}

function getParams() {
  if (isServer()) return
  var query = window?.location.search.slice(1)
  if (query.length === 0) return
  query = query?.split("&")?.reduce((a, c) => {
    var [key, value] = c.split("=")
    return { ...a, [key]: value }
  }, {})
  return query
}

function isOlderThan(ms, dateString) {
  const now = new Date()
  const old = new Date(dateString)

  var isStale = now - old > ms

  return isStale
}

function clean_up_token(interval_id) {
  var r = ["token", "token-date"].forEach((key) => {
    localStorage.setItem(key, "")
    localStorage.removeItem(key)
  })
  if (interval_id) {
    clearInterval(interval_id)
  }
  return r
}

function eraseToken() {
  localStorage.setItem("token", "")
  localStorage.setItem("token-data", "")
  localStorage.removeItem("token")
  localStorage.removeItem("token-data")
}

const compose = (...fns) => (x) => fns.reduceRight((y, f) => f(y), x)

export { compose, eraseToken, isServer, getParams, isOlderThan, clean_up_token }
