import React from "react"
import { ApolloProvider } from "react-apollo"
import ApolloClient from "apollo-boost"
import { InMemoryCache } from "apollo-cache-inmemory"
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory"
import introspectionQueryResultData from "../../config/fragmentTypes.json"
import { isOlderThan } from "./utils/index."

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

const cache = new InMemoryCache({ fragmentMatcher })

const client = new ApolloClient({
  cache,
  uri: `${process.env.GATSBY_WP_URL}/graphql`,
  request: (operation) => {
    var token = localStorage.getItem("token")
    var token_date = localStorage.getItem("token-date")

    var fifteen_minutes_in_ms = "900000"
    token = isOlderThan(fifteen_minutes_in_ms, token_date) ? null : token

    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    })
  },
})

const wrapWithProvider = ({ element }) => {
  return <ApolloProvider client={client}>{element}</ApolloProvider>
}

export default wrapWithProvider
